/*
 * @Descripttion: 
 * @Version: 1.0
 * @Author: pj
 * @Date: 2022-09-28 09:39:04
 * @LastEditors: pj
 * @LastEditTime: 2023-02-27 16:48:26
 * 作品页面请求接口
 */

import walletApi from "@/api/index";
import request from "@/utils/request";

export function getWorksList (params) {
  return request({
    url: walletApi.listWorks,
    method: "get",
    params: params,
  });
}

export async function applyFormDate (data) {
  const result = await request({
    url: walletApi.applyFormDate,
    method: "post",
    data
  });
  return result;
}