<!--
 * @Descripttion: 
 * @Version: 1.0
 * @Author: pj
 * @Date: 2023-02-24 10:03:05
 * @LastEditors: pj
 * @LastEditTime: 2023-02-27 17:24:50
-->
<template>
  <div class="relationship">
    <div class="relationship-menu">
      <page-header></page-header>
    </div>
    <div class="relationship-bg">
      <div class="relationship-bg-top">
        <img src="../../assets/relationship/bg.jpg" alt="" />
      </div>
      <div class="relationship-bg-topContent">
        <img
          src="../../assets/relationship/第一张.png"
          class="relationship-bg-topContent-1"
          alt=""
        />
        <img
          src="../../assets/relationship/第二张.png"
          class="relationship-bg-topContent-2"
          alt=""
        />
        <img
          src="../../assets/relationship/第三张.png"
          class="relationship-bg-topContent-3"
          alt=""
        />
        <img
          src="../../assets/relationship/第四张.png"
          class="relationship-bg-topContent-4"
          alt=""
        />
      </div>
      <div class="relationship-bg-line">
        <img src="../../assets/relationship/第五张.png" alt="" />
      </div>

      <div class="relationship-bg-midContent">
        <img
          src="../../assets/relationship/第六张.png"
          class="relationship-bg-midContent-6"
          alt=""
        />
        <img
          src="../../assets/relationship/第七张.png"
          class="relationship-bg-midContent-7"
          alt=""
        />
      </div>
      <div class="relationship-bg-midPhoto1">
        <img src="../../assets/relationship/第八张.png" alt="" />
      </div>
      <div class="relationship-bg-midPhoto2">
        <img src="../../assets/relationship/第九张.png" alt="" />
      </div>
      <div class="relationship-bg-midPhoto3">
        <img src="../../assets/relationship/第十张.png" alt="" />
      </div>

      <div class="relationship-bg-formData">
        <div>
          <img src="../../assets/relationship/JOIN-THE-COOX.png" alt="" />
        </div>
        <div class="relationship-bg-formData-content">
          <div class="relationship-bg-formData-content-box">
            <el-input placeholder="昵称" v-model="name">
              <i
                slot="suffix"
                class="el-input__icon el-icon-user"
                style="font-size: 20px; color: #606060"
              ></i>
            </el-input>
          </div>

          <div class="relationship-bg-formData-content-box">
            <el-input placeholder="电话" v-model="phone">
              <i
                slot="suffix"
                class="el-input__icon el-icon-mobile-phone"
                style="font-size: 20px; color: #606060"
              ></i>
            </el-input>
          </div>

          <div class="relationship-bg-formData-content-box">
            <el-input placeholder="城市" v-model="city">
              <i
                slot="suffix"
                class="el-input__icon el-icon-location-outline"
                style="font-size: 20px; color: #606060"
              ></i>
            </el-input>
          </div>

          <div class="relationship-bg-formData-content-box">
            <el-autocomplete
              class="inline-input"
              v-model="condition"
              :fetch-suggestions="querySearch"
              placeholder="经营状况"
              @select="handleSelect"
            >
              <i
                slot="suffix"
                class="el-input__icon el-icon-caret-bottom"
                style="font-size: 20px; color: #606060"
              ></i>
              <i
                slot="suffix"
                class="el-input__icon el-icon-data-analysis"
                style="font-size: 20px; color: #606060"
              ></i
            ></el-autocomplete>
          </div>

          <div class="relationship-bg-formData-content-box">
            <el-autocomplete
              class="inline-input"
              v-model="intention"
              :fetch-suggestions="querySearch2"
              placeholder="合作意向"
              @select="handleSelect"
            >
              <i
                slot="suffix"
                class="el-input__icon el-icon-caret-bottom"
                style="font-size: 20px; color: #606060"
              ></i>
              <i
                slot="suffix"
                class="el-input__icon el-icon-data-analysis"
                style="font-size: 20px; color: #606060"
              ></i
            ></el-autocomplete>
          </div>

          <div class="relationship-bg-formData-content-box">
            <el-input placeholder="留言" v-model="remark">
              <i
                slot="suffix"
                class="el-input__icon el-icon-edit-outline"
                style="font-size: 20px; color: #606060"
              ></i>
            </el-input>
          </div>
        </div>

        <div class="relationship-bg-formData-button">
          <img src="../../assets/relationship/7.png" alt="" @click="confirm" />
        </div>
      </div>

      <div class="relationship-bg-bottom">
        <div>
          <img src="../../assets/relationship/第十一张.png" alt="" />
        </div>
        <div class="relationship-bg-bottom-film">
          <img src="../../assets/relationship/第十二张.png" alt="" />
          <img src="../../assets/relationship/第十三张.png" alt="" />
          <img src="../../assets/relationship/第十四张.png" alt="" />
          <img src="../../assets/relationship/第十五张.png" alt="" />
          <img src="../../assets/relationship/第十六张.png" alt="" />
          <img src="../../assets/relationship/第十七张.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import pageHeader from '@/components/page-header'

import { applyFormDate } from '@/api/works/index.js'

export default {
  name: 'relationship',
  components: {
    pageHeader,
  },
  data() {
    return {
      name: '',
      phone: '',
      city: '',
      condition: '',
      intention: '',
      remark: '',
      conditionList: [],
      intentionList: [],
    }
  },
  methods: {
    hasEmptyProperty(obj) {
      for (let key in obj) {
        if (obj[key] === null || obj[key] === '') {
          return false
        }
      }
      return true
    },
    querySearch(queryString, cb) {
      var conditionList = this.conditionList
      var results = conditionList
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    querySearch2(queryString, cb) {
      var intentionList = this.intentionList
      var results = intentionList
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
          0
        )
      }
    },
    loadAll() {
      return [
        { value: '实体经营者', address: '实体经营者' },
        { value: '创业者', address: '创业者' },
      ]
    },
    loadAll2() {
      return [
        { value: '大喜', address: '大喜' },
        { value: '小喜', address: '小喜' },
      ]
    },
    handleSelect(item) {
      console.log(item)
    },
    async confirm() {
      const { name, phone, city, condition, intention } = { ...this }
      const newObj = { name, phone, city, condition, intention }
      // const copyObject = (obj, props) => {
      //   const newObj = {}
      //   props.forEach((prop) => {
      //     newObj[prop] = obj[prop]
      //   })
      //   return newObj
      // }
      // const newObj = copyObject(this, [
      //   'name',
      //   'phone',
      //   'city',
      //   'condition',
      //   'intention',
      // ])
      const result = this.hasEmptyProperty(newObj)
      if (result) {
        const result2 = await applyFormDate(newObj)
        if (result2.code === 200) {
          this.$message({
            message: '提交成功',
            type: 'success',
          })
          this.name = ''
          this.phone = ''
          this.city = ''
          this.condition = ''
          this.intention = ''
        }
      } else {
        this.$alert('请完全填好后再进行提交哦', '提交表单缺少参数', {
          confirmButtonText: '确定',
          callback: (action) => {},
        })
      }
      console.log(result)
    },
  },
  mounted() {
    ;(this.conditionList = this.loadAll()),
      (this.intentionList = this.loadAll2())
  },
}
</script>
<style lang="less" scoped>
.relationship {
  &-menu {
    position: fixed;
    z-index: 999;
    width: 100%;
    background-color: #ffffff;
  }
  &-bg {
    background-color: #f7f7f7;
    height: auto;
    width: 100%;
    &-top {
      display: flex;
      flex-direction: column;
      position: relative;
    }
    &-topContent {
      position: absolute;
      top: 156px;
      left: 283px;
      display: flex;
      flex-direction: column;
      &-1 {
        width: 382px;
        height: 52px;
        margin-bottom: 35px;
      }
      &-2 {
        width: 604px;
        height: 210px;
        margin-bottom: 25px;
      }
      &-3 {
        width: 553px;
        height: 74px;
        margin-bottom: 42px;
      }
      &-4 {
        width: 555px;
        height: 118px;
      }
    }
    &-line {
      position: absolute;
      top: 762px;
      left: 283px;
      img {
        width: 7px;
        height: 110px;
      }
    }
    &-midContent {
      position: absolute;
      top: 903px;
      left: 283px;
      display: flex;
      flex-direction: column;
      &-6 {
        width: 332px;
        height: 86px;
        margin-bottom: 50px;
      }
      &-7 {
        width: 1319px;
        height: 270px;
      }
    }
    &-midPhoto1 {
      position: absolute;
      top: 1307px;
      left: 195px;
      display: flex;
      img {
        width: 498px;
        height: 398px;
      }
    }
    &-midPhoto2 {
      position: absolute;
      top: 1307px;
      left: 567px;
      display: flex;
      img {
        width: 498px;
        height: 398px;
      }
    }
    &-midPhoto3 {
      position: absolute;
      top: 1307px;
      left: 937px;
      display: flex;
      img {
        width: 498px;
        height: 398px;
      }
    }
    &-formData {
      position: absolute;
      top: 1731px;
      left: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      &-button {
        width: 422px;
        height: 88px;
        margin-top: 50px;
      }
      &-content {
        display: flex;
        justify-content: space-around;
        width: 1250px;
        margin-top: 25px;
        flex-wrap: wrap;
        &-box {
          width: 390px;
          height: 70px;
          background-color: #e6e6ed;
          border-radius: 90px;
          display: flex;
          justify-content: center;
          align-items: center;
          box-sizing: border-box;
          padding: 0 10px 0 10px;
          margin-top: 25px;

          /deep/ .el-input__inner {
            border: 0 !important;
            background-color: #e6e6ed;
            font-size: 20px;
            color: #606060;
            outline: none;
          }
        }
      }
    }
    &-bottom {
      position: absolute;
      top: 2312px;
      left: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      img {
        width: 532px;
        height: 86px;
        margin-bottom: 50px;
      }
      &-film {
        display: flex;
        justify-content: space-around;
        img {
          width: 221px;
          height: 147px;
        }
      }
    }
  }
}
</style>
